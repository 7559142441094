<template>
  <div class="list">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="search" placeholder="请输入供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :row-class-name="rowBackground">
          <el-table-column prop="number" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="supplierName" label="供应商名称" width="220" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contacts" label="联系人" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="telphone" label="联系电话" width="140" show-overflow-tooltip></el-table-column>
          <el-table-column label="供应商简介" width="180" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="desc">{{ scope.row.supplierIntroduce }}</span>
            </template>
          </el-table-column>
          <el-table-column label="营业执照" min-width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="show_img" @click="previewImage(scope.row.busiLicense)">预览图片</span>
            </template>
          </el-table-column>
          <el-table-column prop="creditCode" label="统一社会信用代码" width="220" show-overflow-tooltip></el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.switch" active-color="#069BBC" inactive-color="#E2E2E2" @change="changeStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="eligibleName" label="应标资格" min-width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.eligibleName ? scope.row.eligibleName : "无" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right" width="200">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="small" plain @click="showSupply(scope.row)">供货关系</el-button>
              <el-button class="btn" type="primary" size="small" plain @click="showDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false
        }
      "
      :url-list="imgList"
    ></el-image-viewer>
  </div>
</template>

<script>
export default {
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      loading: false,
      search: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）

      // 图片预览
      showViewer: false,
      imgList: [],
    }
  },
  created() {
    this.getSupplierInfoPageList()
  },
  methods: {
    // 供应商列表
    getSupplierInfoPageList() {
      this.loading = true
      this.$axios
        .get(this.$api.supplierInfoPageList, {
          params: {
            supplierName: this.search,
            page: this.currentPage,
            pageSize: this.pageSize,
            type: "0", // 列表类型(0:供应商列表 1:供应商审核)
            status: "", // 审核状态 ( 0:待审核 1:审核通过 2:审核不通过 3:停用)
          },
        })
        .then(res => {
          let list = res.data.result.list.map(e => {
            return {
              ...e,
              switch: e.status == "1" ? true : false,
            }
          })
          this.tableData = list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 表格行未读时的背景色
    rowBackground(e) {
      if (e.row.updateStatus == 0) {
        return "unread"
      }
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getSupplierInfoPageList()
    },
    // 切换状态
    changeStatus(row) {
      const originalSwitch = row.switch
      this.$confirm(`该供应商即将${row.switch ? "启用" : "停用"}，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .put(this.$api.supplierInfoEnable, {
              supplierId: row.supplierId,
              status: row.switch ? "1" : "3",
            })
            .then(() => {
              this.$set(row, "switch", originalSwitch)
              this.$message.success("操作成功")
              this.getSupplierInfoPageList()
            })
            .catch(() => {
              this.$set(row, "switch", !originalSwitch)
              this.$message.error("操作失败")
              this.getSupplierInfoPageList()
            })
        })
        .catch(() => {
          this.$set(row, "switch", !originalSwitch)
        })
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getSupplierInfoPageList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getSupplierInfoPageList()
    },
    // 查看
    showDetail(row) {
      this.$router.push({ path: "/supplier/detail/supplierDetail", query: { id: row.supplierId } })
    },
    showSupply(row) {
      this.$router.push({ path: "/supplier/detail/supplyList", query: { id: row.supplierId } })
    },
    // 图片预览
    previewImage(url) {
      this.imgList[0] = url
      this.showViewer = true
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  ::v-deep .unread {
    background-color: rgb(255, 227, 231) !important;
  }
}
</style>
